//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'homepage',
  async asyncData({ $api }) {
    const { main_banner: banner, mobile_banner: mobileBanner } =
      await $api.shopSettings()

    return { banner, mobileBanner }
  },
  computed: {
    bannerUrl() {
      return this.banner?.url || 'https://via.placeholder.com/1000x500&text='
    },
    mobileBannerUrl() {
      return this.mobileBanner?.url
    },
  },
  methods: {
    bannerSizeUrl(size = 'large') {
      return this.banner?.sizes[size] || this.bannerUrl
    },
  },
  head() {
    return {
      htmlAttrs: {
        class: ['static-page'],
      },
      bodyAttrs: {
        class: ['static-page'],
      },
    }
  },
}
